import { pushToDataLayerOnce } from "./pushToDataLayerOnce"

export const sendScrollEventToDataLayer = (id: string) => {
  if (id === "formPkw") {
    pushToDataLayerOnce("formPkw", { event: "Scroll_segment", name: "banner" })
    return
  }
  if (id === "textBottomCalculator") {
    pushToDataLayerOnce("textBottomCalculator", { event: "Scroll_segment", name: "calculator" })
    return
  }
  if (id === "partners") {
    pushToDataLayerOnce("partners", { event: "Scroll_segment", name: "form_and_benefits" })
    return
  }
  if (id === "questions") {
    pushToDataLayerOnce("question", { event: "Scroll_segment", name: "shops" })
    return
  }
  if (id === "footer") {
    pushToDataLayerOnce("footer", { event: "Scroll_segment", name: "faq" })
  }
}
